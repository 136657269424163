.header-no-img{
  width: auto;
	height: auto;
	background: none;
	text-indent: 0;
	font-family: 'Coming Soon', cursive;
}

button{
	background-color: black;
	border: 1px solid white;
	font-family: 'Coming Soon', cursive;
	font-size: 15px;
	color: white;
	cursor: pointer;
	padding: 10px;
}