#page-blog{
	.blog-header{
		margin-bottom: 25px;
		font-family: 'Coming Soon', cursive;
		h1{
			height: auto;
			margin: 44px 0 0 0;
			text-align: center;
			display: inline-block;
		}
		p{
			margin: 0;
		}
	}
	.blog-item{
		font-family: 'Coming Soon', cursive;
		padding: 20px 0;
		border-bottom: 1px solid #ffffff;
		text-align: left;
		.blog-item-header{
			margin: 0 0 20px 0; 
			font-size: 21px;
			.date-posted{
				font-size: 13px;
		    font-weight: 400;
		    margin-left: 12px;
			}
		}
		img{
			display: block;
			height: auto;
	    max-width: 100%;
		}
	}
}