#page-tour{
	.upcoming-tour{
		margin-bottom: 40px;
	}
	.past-tour{
		max-height: 0;
		overflow: hidden;
		transition: 0.5s max-height;
		padding-top: 20px;
		&.expanded{
			max-height: 10000px;
		}
		p{
			margin-top: 0;
			margin-bottom: 10px;
		}
	}
	.header-press{
		margin-top: 40px;
	}
}